import { useState, useEffect } from 'react'
import Footer from './components/Footer/Footer';
import AppHeader from './components/Header/AppHeader';
import Login from "./components/Login/Login";
import Main from "./components/Main/Main";
import { HttpRequest } from './utils/HttpRequest';
import './App.scss'

function App() {
    const [token, setToken] = useState('')
    const [backendVersion, setBackendVersion] = useState<any>()
    const [expiration_date, setExpiration_date] = useState('')
    const [baseUrl, setBaseUrl] = useState('')
    const [selectableManufacturers, setSelectableManufacturers] = useState<string>()
    const [storedToken, setstoredToken] = useState(sessionStorage.getItem('token'))

    // Token has a validation that's why it has to be calculated according to this time.
    /** Returns the remaining time from token's validation
   * @returns executionTime-time to delete token from storage
   */

    const setExecutionTime = () => {
        // At the beginning there is no expiration_date. It comes first with login
        if (sessionStorage.getItem("expiration_date") || expiration_date) {
            // If user renders the page state is deleted. So we check it
            let lastTime = expiration_date ? new Date(expiration_date).getTime() : new Date(sessionStorage.getItem("expiration_date") as string).getTime()

            // Delay for downloading the documents, 5 Minutes
            const documentDownloadTime = 300000
            lastTime -= documentDownloadTime

            const now = new Date().getTime()
            return lastTime - now
        }
    }

    if (token || storedToken) {
        let executionTime = setExecutionTime()
        setTimeout(() => {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("expiration_date")
            setToken('')
            setstoredToken('')
            alert('Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein')
        }, executionTime)
    }

    const getBackendInfo = async () => {
        try {
            const { backendVersion, baseUrl, selectableManufacturers } = await new HttpRequest().getBackendInfo()
            setBackendVersion(backendVersion)
            setBaseUrl(baseUrl)
            setSelectableManufacturers(selectableManufacturers)
            sessionStorage.setItem('backendVersion', backendVersion)
            sessionStorage.setItem('baseUrl', baseUrl)
            sessionStorage.setItem('selectableManufacturers', selectableManufacturers)
        } catch (error) {
            console.log("Error on getting version number from backend :", error)
        }
    }

    useEffect(() => {
        if (!sessionStorage.getItem('backendVersion'))
            getBackendInfo()
    }, [])

    return (
        <div className='app_wrapper'>
            <AppHeader token={token} />
            {(token || storedToken) ?
                <Main selectableManufacturers={selectableManufacturers} /> :
                <Login
                    setToken={setToken}
                    setExpiration_date={setExpiration_date}
                    baseUrl={baseUrl}
                />
            }
            <Footer
                token={token}
                baseUrl={baseUrl}
                backendVersion={backendVersion} />
        </div>
    );
}

export default App;

