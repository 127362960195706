import "./NoPermissionInfo.scss"

function NoPermissionInfo() {
    return (
        <div className="permission_wrapper">
            <p>Ooops! Etwas ist schief gegangen!</p>
            <p>Sie benötigen manche Berechtigungen, um weiter zu machen.</p>
        </div>
    )
}

export default NoPermissionInfo
