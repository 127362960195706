import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import qrCode from '../../../assets/qrCode.png';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { RequestPaths } from '../../../models/RequestPaths';
import { BackendResult } from '../../../utils/BackendResult';
import { HttpRequest } from '../../../utils/HttpRequest';
import ModalApp from '../../Modal/Modal';

const ConexoUploadKrohne = ({ manufacturer, setConexoResponse, setLoading, loading }) => {
    const [digitalLink, setDigitalLink] = useState('')
    const [onClose, setOnClose] = useState(false);
    const [alertOpt, setAlertOpt] = useState({ message: '', isOpen: false });
    const url = RequestPaths.saveInConexoPathKrohne

    const isDigitalLinkCorrect = (): boolean => {
        if (digitalLink.startsWith('dev-sn.com/')) {
            return true
        } else {
            setAlertOpt({ message: "Eingabe Format ist nicht richtig", isOpen: true })
            setTimeout(() => {
                setAlertOpt({ message: "", isOpen: false })
            }, 5000)
            return false
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!isDigitalLinkCorrect()) {
            return
        }
        setLoading(true)
        try {
            let conexoResponse = await new HttpRequest().saveInConexo(url, digitalLink)
            setConexoResponse(conexoResponse)
        } catch (error) {
            errorHandling(error)
        }
        setDigitalLink('')
        setLoading(false)
    };

    const errorHandling = (error) => {
        setConexoResponse(new BackendResult(false, null, error.response?.statusText ?? "Unerwateter Fehler"))
    }

    function toggleModal() {
        setOnClose(!onClose);
    }
    return (
        <>
            {manufacturer === GeneralAttributes.krohne ?
                <div className="conexoUpload-wrapper">
                    <Alert className="alert" variant="warning" show={alertOpt.isOpen} >
                        {alertOpt.message}
                    </Alert>
                    <p className="order-text">Geben Sie Ihr Identification Link für Conexo ein</p>

                    <form onSubmit={onSubmit}>
                        <div className="input-wrapper">
                            <img
                                className="code-image"
                                src={qrCode}
                                alt="Qr Code"
                                onClick={toggleModal} />

                            <div className="input-group test">
                                <Button
                                    disabled={loading}
                                    className="scan-button"
                                    variant="outline-dark"
                                    bsPrefix="scan-button"
                                    onClick={toggleModal} >
                                    Scannen
                                </Button>
                                <input
                                    required={true}
                                    className="input-serialnumber"
                                    placeholder='Geben Sie Identification Link ein'
                                    onChange={(e) => { setDigitalLink(e.target.value.trim()) }}
                                    value={digitalLink}
                                    onFocus={() => setConexoResponse('')}
                                />
                            </div>
                        </div>

                        <Button
                            disabled={loading}
                            type="submit"
                            className="submit-button"
                            variant="primary"
                            bsPrefix="submit-button"
                        > In CONEXO speichern </Button>

                        <ModalApp
                            toggleModal={toggleModal}
                            onClose={onClose}
                            setInputValue={setDigitalLink}
                            setOnClose={setOnClose} />
                    </form>
                </div>
                : null}
        </>
    )
}

export default ConexoUploadKrohne