import greenTick from "../../../assets/greenTick.jpg";
import redCross from "../../../assets/red.jpg";
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { INetilionResponse } from '../../../models/INetilionResponse';
import Loading from '../../Loading/Loading';
import "./NetilionResponse.scss";

interface NetilionResponseProps {
    netilionResponse: INetilionResponse,
    loading: boolean,
    manufacturer: string
}

const NetilionResponse = ({ netilionResponse, loading, manufacturer }: NetilionResponseProps) => {
    const isLoading = loading && !netilionResponse.serialNumber && manufacturer !== GeneralAttributes.ari && manufacturer !== GeneralAttributes.krohne
    const successView = () => {
        return (
            <>
                <div className="found">
                    <img className="netilion-result-image" src={greenTick} alt="greenTick" />
                </div>
                <div className="found">
                    <p className='result-text'>{netilionResponse.manufacturer?.manufacturer?.name}</p>
                    <p className='result-text'>Seriennmumer: {netilionResponse?.serialNumber}</p>
                </div>
            </>
        )
    }

    const unsuccessView = () => {
        return (
            <>
                <div className="not-found">
                    <img className="netilion-result-image" src={redCross} alt="unsuccessful" />
                    <p className='result-text'>{netilionResponse?.message ? netilionResponse.message : "Unerwarter Fehler"}</p>
                </div>
            </>
        )
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            {netilionResponse?.serialNumber ?
                <div className="response-wrapper">
                    {netilionResponse.isSuccess === true ? successView() : null}
                    {netilionResponse.isSuccess === false ? unsuccessView() : null}
                </div>
                : null}
        </>
    )
}

export default NetilionResponse