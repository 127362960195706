import logo from '../../assets/conexo.png'
import "./AppHeader.scss"

function AppHeader({ token }) {
    const windowWidth = window.innerWidth

    const getClassName = () => {
        if (token || sessionStorage.getItem('token')) {
            return "header_wrapper_main"
        } else if (windowWidth > 1280) {
            return "header_wrapper_login"
        } else {
            return "header_wrapper_main"
        }
    }

    return (
        <div className={getClassName()}>
            <img className="header_logo" src={logo} alt="conexo-logo" />
            <p className='icon_text'>OnlineDataConverter</p>
            <p className='icon_border'></p>
        </div>
    )
}

export default AppHeader
