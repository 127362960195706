import { useState } from 'react';
import { Button } from 'react-bootstrap';
import qrCode from '../../../assets/qrCode.png';
import { INetilionResponse } from '../../../models/INetilionResponse';
import { RequestPaths } from '../../../models/RequestPaths';
import { BackendResult } from '../../../utils/BackendResult';
import { HttpRequest } from '../../../utils/HttpRequest';
import ModalApp from '../../Modal/Modal';
import './ConexoUploadNetilion.scss';

interface ConexoUploadNetilionProps {
    netilionResponse: INetilionResponse,
    setConexoResponse: (item: BackendResult | null) => void,
    loading: boolean,
    setLoading: (item: boolean) => void
}
const ConexoUploadNetilion = ({ netilionResponse, setConexoResponse, setLoading, loading }: ConexoUploadNetilionProps) => {
    const [digitalLink, setDigitalLink] = useState('')
    const [onClose, setOnClose] = useState(false);
    const uploadData = { netilionResponse, digitalLink }
    const url = RequestPaths.saveInConexoPathNetilion

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            let conexoResponse = await new HttpRequest().saveInConexo(url, uploadData)
            setConexoResponse(conexoResponse)
        } catch (error) {
            errorHandling(error)
        }
        setLoading(false)
        setDigitalLink('')
    };

    const errorHandling = (error) => {
        setConexoResponse(new BackendResult(false, null, error.response?.statusText ?? "Unerwateter Fehler"))
    }

    function toggleModal() {
        setOnClose(!onClose);
    }
    return (
        <>
            {netilionResponse?.isSuccess === true ?
                <div className="conexoUpload-wrapper">
                    <p className="order-text">CONEXO-QR-CODE auf das Produkt aufbringen und scannen</p>

                    <form onSubmit={onSubmit}>
                        <div className="input-wrapper">
                            <img
                                className="code-image"
                                src={qrCode}
                                alt="Qr Code"
                                onClick={toggleModal} />

                            <div className="input-group">
                                <Button
                                    disabled={loading}
                                    className="scan-button"
                                    variant="outline-dark"
                                    bsPrefix="scan-button"
                                    onClick={toggleModal} >
                                    Scannen
                                </Button>
                                <input
                                    type="url"
                                    required={true}
                                    className="input-serialnumber"
                                    placeholder='Geben Sie Identification Link ein'
                                    onChange={(e) => { setDigitalLink(e.target.value.trim()) }}
                                    value={digitalLink}
                                    onFocus={() => setConexoResponse(null)}
                                />
                            </div>
                        </div>

                        <Button
                            disabled={loading}
                            type="submit"
                            className="submit-button"
                            variant="primary"
                            bsPrefix="submit-button"
                        > In CONEXO speichern </Button>

                        <ModalApp
                            toggleModal={toggleModal}
                            onClose={onClose}
                            setInputValue={setDigitalLink}
                            setOnClose={setOnClose} />
                    </form>
                </div>
                : null}
        </>
    )
}

export default ConexoUploadNetilion