import React, { useEffect, useState } from "react";
import Manufacturer from "./Manufacturer/Manufacturer";
import NetilionUpload from "./NetilionUpload/NetilionUpload";
import "./Main.scss";
import NetilionResponse from "./NetilionResponse/NetilionResponse";
import ConexoUploadNetilion from "./ConexoUploadNetilion/ConexoUploadNetilion";
import ConexoUploadAri from "./ConexoUploadAri/ConexoUploadAri";
import ConexoResponse from "./ConexoResponse/ConexoResponse";
import ConexoUploadKrohne from "./ConexoUploadKrohne/ConexoUploadKrohne"
import { HttpRequest } from "../../utils/HttpRequest";
import { RequestPaths } from "../../models/RequestPaths";
import NoPermissionInfo from "../NoPermissionInfo/NoPermissionInfo";
import { PermissionCheck } from "../../utils/PermissionCheck";
import { IPermissionLists } from "../../models/IPermissionLists";
import Loading from "../Loading/Loading";
import UnexpectedError from "../UnexpectedError/UnexpectedError";
import { INetilionResponse } from "../../models/INetilionResponse";
import { BackendResult } from "../../utils/BackendResult";

interface MainProps {
    selectableManufacturers: string | undefined
}

const Main = ({ selectableManufacturers }: MainProps) => {
    const [netilionResponse, setNetilionResponse] = useState<INetilionResponse>();
    const [conexoResponse, setConexoResponse] = useState<BackendResult | null>();
    const [manufacturer, setManufacturer] = useState("");
    const [hasPermission, setHasPermission] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false)
    const [unexpectedError, setUnexpectedError] = useState<boolean>(false)

    const getPermissions = async () => {
        const url: string = RequestPaths.getPermissions
        try {
            const permissionLists = await new HttpRequest().getPermissions(url) as unknown as IPermissionLists
            setHasPermission(PermissionCheck.check(permissionLists))
        } catch (error) {
            console.log('Error on getting permissions : ', error);
            setUnexpectedError(true)
        }
    }

    useEffect(() => {
        getPermissions()
        setConexoResponse(null)
    }, [netilionResponse]);

    const permissonView = () => {
        return (
            <>
                {hasPermission === undefined ? <Loading /> :
                    <NoPermissionInfo />}
            </>
        )
    }
    const unexpectedErrorView = () => {
        return (
            <UnexpectedError />
        )
    }

    const mainView = () => {
        return (
            <div className="form-wrapper">
                <Manufacturer
                    selectableManufacturers={selectableManufacturers}
                    setManufacturer={setManufacturer}
                    setNetilionResponse={setNetilionResponse}
                    loading={loading}
                />
                <NetilionUpload
                    setNetilionResponse={setNetilionResponse}
                    manufacturer={manufacturer}
                    setLoading={setLoading}
                    loading={loading}
                />
                <NetilionResponse
                    netilionResponse={netilionResponse!}
                    loading={loading}
                    manufacturer={manufacturer}
                />
                <ConexoUploadNetilion
                    netilionResponse={netilionResponse!}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoUploadAri
                    manufacturer={manufacturer}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoUploadKrohne
                    manufacturer={manufacturer}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoResponse
                    netilionResponse={netilionResponse!}
                    conexoResponse={conexoResponse!}
                    loading={loading}
                    manufacturer={manufacturer}
                />
            </div>
        )
    }

    const setView = () => {
        if (unexpectedError) {
            return unexpectedErrorView()
        } else if (hasPermission) {
            return mainView()
        } else {
            return permissonView()
        }
    }
    return (
        <div className="main-wrapper">
            {setView()}
        </div>
    );
};

export default Main;
