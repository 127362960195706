import greenTick from "../../../assets/greenTick.jpg"
import redCross from "../../../assets/red.jpg"
import Loading from "../../Loading/Loading"
import { GeneralAttributes } from "../../../models/GeneralAttributes"
import { BackendResult } from "../../../utils/BackendResult"
import { INetilionResponse } from "../../../models/INetilionResponse"
import "./ConexoResponse.scss"

interface ConexoResponseProps {
    conexoResponse: BackendResult | null
    loading: boolean,
    netilionResponse: INetilionResponse,
    manufacturer: string
}

const ConexoResponse = ({ conexoResponse, loading, netilionResponse, manufacturer }: ConexoResponseProps) => {
    let isLoading = loading && (netilionResponse.serialNumber || manufacturer === GeneralAttributes.ari || manufacturer === GeneralAttributes.krohne)

    const successView = () => {
        return (
            <>
                <div className="conexo-found">
                    <img className="result-image" src={greenTick} alt="greenTick" />
                </div>
                <div className="conexo-found">
                    <p className='result-text'>Erfolgreich</p>
                </div>
            </>
        )
    }

    const unsuccessView = (conexoResponse: BackendResult) => {
        return (
            <div>
                <h5 className='error_title'>Error</h5>
                <div className="conexo-not-found">
                    <img className="result-image" src={redCross} alt="redCross" />
                    {arrangeErrorMessages(conexoResponse)}
                </div>
            </div>
        )
    }

    const arrangeErrorMessages = (conexoResponse: BackendResult) => {
        if (conexoResponse.error) {
            return <p>{conexoResponse.error}</p>
        } else if (conexoResponse.data?.error) {
            // The error messages from the portal include HTML tags, for example <bold>Digital ID</bold>
            // That's why we use **dangerouslySetInnerHTML**
            return <p className='result-text' dangerouslySetInnerHTML={{ __html: conexoResponse.data.error }} />
        } else {
            return <p className='result-text'> Etwas ist schief gegangen</p>
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            {conexoResponse ?
                <div className="conexo-response-wrapper">
                    {conexoResponse.success ? successView() : unsuccessView(conexoResponse)}
                </div>
                : null}
        </>
    )
}

export default ConexoResponse