import { useState } from 'react';
import { Button } from 'react-bootstrap';
import qrCode from '../../../assets/datamatrix.jpg';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { INetilionResponse } from '../../../models/INetilionResponse';
import { BackendResult } from '../../../utils/BackendResult';
import { HttpRequest } from '../../../utils/HttpRequest';
import ModalApp from '../../Modal/Modal';
import './NetilionUpload.scss';


interface NetilionUploadProps {
    setNetilionResponse: (item: INetilionResponse) => void,
    setLoading: (item: boolean) => void,
    manufacturer: string,
    loading: boolean
}
const NetilionUpload = ({ setNetilionResponse, manufacturer, setLoading, loading }: NetilionUploadProps) => {
    const [inputValue, setInputValue] = useState('')
    const [onClose, setOnClose] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            let result = await new HttpRequest().getFromNetilion(inputValue)
            successHandling(result)
        } catch (error) {
            errorHandling(error)
        }
        setLoading(false)
        setInputValue('')
    };

    const successHandling = (result: BackendResult) => {
        if (result.success) {
            setNetilionResponse({
                manufacturer: result.data.manufacturerNetilion,
                productIdNetilion: result.data.productIdNetilion,
                serialNumber: inputValue,
                isSuccess: true,
            })
        } else {
            setNetilionResponse({
                serialNumber: inputValue,
                isSuccess: false,
                message: result.error
            })
        }
    }

    const errorHandling = (error) => {
        setNetilionResponse({
            serialNumber: inputValue,
            isSuccess: false,
            message: error?.data ?? "Unerwarteter Fehler"
        })
    }

    function toggleModal() {
        setOnClose(!onClose);
    }

    return (
        <>
            {manufacturer === GeneralAttributes.netilion ?
                <div className="serialNumber-wrapper">
                    <p className="order-text">Data-Matrix-Code auf dem Product scannen oder Seriennummer eingeben</p>

                    <form onSubmit={handleSubmit}>
                        <div className="input-wrapper">
                            <img
                                className="code-image"
                                src={qrCode}
                                alt="Qr Code"
                                onClick={toggleModal} />

                            <div className="input-group">
                                <Button
                                    disabled={loading}
                                    className="scan-button"
                                    variant="outline-dark"
                                    bsPrefix="scan-button"
                                    onClick={toggleModal} >
                                    Scannen
                                </Button>
                                <input
                                    required={true}
                                    style={{ height: "30px" }}
                                    className="input-serialnumber"
                                    placeholder='Bitte geben Sie die Seriennummer ein'
                                    type="text"
                                    onChange={(e) => setInputValue(e.target.value.trim())}
                                    value={inputValue}
                                    onFocus={() => setNetilionResponse({ serialNumber: '' })}
                                />
                            </div>
                        </div>

                        <Button
                            disabled={loading}
                            type="submit"
                            className="submit-button"
                            variant="primary"
                            bsPrefix="submit-button"
                            data-testid="submit-button"
                        > Weiter
                        </Button>

                        <ModalApp
                            toggleModal={toggleModal}
                            onClose={onClose}
                            setInputValue={setInputValue}
                            setOnClose={setOnClose} />

                    </form>
                </div>
                : null}
        </>
    )
}

export default NetilionUpload