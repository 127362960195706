import { useState } from 'react';
import { Alert, Button } from "react-bootstrap";
import { GeneralAttributes } from '../../models/GeneralAttributes';
import { RequestPaths } from '../../models/RequestPaths';
import { HttpRequest } from '../../utils/HttpRequest';
import ImpressumDatasecurity from '../ImpirintDatasecurity/ImpirintDatasecurity';
import './Login.scss';

const Login = ({ setToken, setExpiration_date, baseUrl }) => {

  const [showAlert, setShowAlert] = useState(false)
  const [unexpectedError, setUnexpectedError] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const loginData = { username: username, password: password }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let conexoResponse = await new HttpRequest().login(loginData)
      successHandling(conexoResponse)
    } catch (error) {
      console.log(error)
      errorHandling(error)
    }
  };

  const successHandling = (result) => {
    setExpiration_date(result.expiration_date)
    setToken(result.token)
    sessionStorage.setItem('expiration_date', result.expiration_date)
    sessionStorage.setItem('token', result.token)
  }

  const errorHandling = (error) => {
    setShowAlert(true)
    setPassword('')
    error?.response?.status ? setUnexpectedError(false) : setUnexpectedError(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 5000)
  }

  return (
    <div className="login-wrapper">
      <Alert className="alert" variant="warning" show={showAlert} >
        {unexpectedError ? GeneralAttributes.errorUnexpected : GeneralAttributes.errorWrongCredentials}
      </Alert>
      <p className="login-header">Bitte anmelden</p>
      <div className="register-form">
        <form data-testid="form" onSubmit={handleSubmit} className='login-form'>
          <div className="form-group">
            <input
              value={username}
              required={true}
              type="text"
              placeholder='Benutzername'
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              data-testid="name-input"
            />
          </div>

          <div className="form-group">
            <input
              required={true}
              type="password"
              value={password}
              placeholder='Passwort'
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              data-testid="password-input"
            />
          </div>
          <div className="form-group">
            <p className="form-text">Nutzen Sie Ihre Zugangsdaten vom Portal</p>
          </div>

          <div className="form-group">
            <Button data-testid="submit-button" type="submit" className="custom-btn">
              {GeneralAttributes.signIn}
            </Button>
          </div>
        </form>
      </div>

      <div className="impirint_data">
        <ImpressumDatasecurity
          baseUrl={baseUrl} />
      </div>

    </div>
  );
};

export default Login;