import React from 'react'
import version from '../../version.json'
import ImpirintDatasecurity from '../ImpirintDatasecurity/ImpirintDatasecurity'
import './Footer.scss'

function Footer({ backendVersion, baseUrl, token }) {
    const getBackendVersion = () => {
        if (sessionStorage.getItem('backendVersion')) {
            return "BE: " + sessionStorage.getItem('backendVersion')
        } else if (backendVersion) {
            return "BE: " + backendVersion
        } else {
            return ''
        }
    }

    const getFrontendVersion = () => {
        return "FE: " + version.version
    }

    const displayImpirintAndDatasecurity = () => {
        if (sessionStorage.getItem('token') || token) {
            return <ImpirintDatasecurity
                baseUrl={baseUrl} />
        } else {
            return null
        }
    }
    return (
        <div className='footer_wrapper'>
            {displayImpirintAndDatasecurity()} {getFrontendVersion()}&nbsp; {getBackendVersion()}
        </div>
    )
}

export default Footer
