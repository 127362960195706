export const requiredPermissions = {
    "importList": [
        "productI18N",
        "product",
        "manufacturer",
        "customDocument",
        "documentI18N",
        "keyValue"
    ],
    "exportList": [
        "keyValue",
        "documentI18N",
        "customDocument",
        "manufacturer",
        "product",
        "productI18N",
        "component",
    ]
}