import React from 'react'
import "./UnexpectedError.scss"
function UnexpectedError() {
    return (
        <div className="unexpected_wrapper">
            <p>Ooops! Etwas ist schief gegangen!</p>
        </div>
    )
}

export default UnexpectedError
