import axios from 'axios'
import { IHttpResult } from '../models/IHttpResult'
import { IBackendInfo } from '../models/IBackendInfo'
import { ILoginAttributes } from '../models/LoginAttributes'
import { RequestPaths } from '../models/RequestPaths'
import { BackendResult } from './BackendResult'

export class HttpRequest {

    async login(data: ILoginAttributes): Promise<IHttpResult> {
        return new Promise((resolve, reject) => {

            axios.post(RequestPaths.loginPath, data).then(result => {
                resolve(result.data)
            }).catch(err => {
                if (err.response) {
                    console.log("Error on Login, Status Code: ", err.response.status)
                    reject(err)
                }
                else {
                    console.log('err', err);
                    reject(err)
                }
            })
        })
    }

    async getFromNetilion(serial_number: string): Promise<BackendResult> {
        let data = { serial_number }
        return axios.post(RequestPaths.serialnumberPath, data)
            .then(result => result.data)
            .catch(err => err.resonse)
    }

    // save in conexo
    async saveInConexo(url: string, uploadData): Promise<BackendResult> {
        let token = sessionStorage.getItem('token')
        let data = { uploadData, token }
        return axios.post(url, data)
            .then(result => result.data)
            .catch(err => err.resonse)
    }

    async getPermissions(url: string) {
        let token = sessionStorage.getItem('token')
        let data = { token }
        return new Promise((resolve, reject) => {
            axios.post(url, data).then(result => {
                resolve(result.data)
            }).catch(error => {
                reject(error)
            })
        })
    }

    async getBackendInfo(): Promise<IBackendInfo> {
        return new Promise((resolve, reject) => {
            axios.get(RequestPaths.getBackendInfo).then(result => {
                resolve(result.data)
            }).catch(error => {
                reject(error)
            })
        })
    }

}